


































































































































import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { UtilsString } from "@/utils/utils-string";
import { UtilsPublicResources } from "@/utils/utils-public-resources";
import entrevistaModule from "@/store/modules/entrevista-module";
import { UtilsNumber } from "@/utils/utils-number";
@Component({
  components: { CompHeader: () => import("./CompHeaderLabel.vue") },
})
export default class ComSlider extends Vue {
  @Prop() index!: number;
  @PropSync("valor", { type: Number }) syncedvalor!: number;
  @Prop({
    default: "min",
    required: false,
  })
  min!: number;
  @Prop({
    default: "max",
    required: false,
  })
  max!: number;
  @Prop({
    default: "label",
    required: false,
  })
  label!: string;

  @Prop({
    default: "unidadmedida",
    required: false,
  })
  unidadmedida!: string;

  @Prop({
    default: false,
    required: false,
  })
  isDecimal!: boolean;
  @Prop({ default: 0 }) tipo_inteligencia!: number;
  public created() {
    this.$emit("validado", { index: this.index, valido: true });
  }
  public get step() {
    if (this.isDecimal) {
      return 0.01;
    }
    return 1;
  }
  public get getvalue() {
    return UtilsString.ValueOf(this.syncedvalor);
  }

  public get color() {
    if (this.syncedvalor < 50) return "orange";
    if (this.syncedvalor < 80) return "teal";
    if (this.syncedvalor < 90) return "green";
    if (this.syncedvalor < 125) return "indigo";
    if (this.syncedvalor < 140) return "indigo darken-1";
    if (this.syncedvalor < 150) return "indigo darken-2";
    if (this.syncedvalor < 175) return "orange";
    return "#EBBE58";
  }

  public getimagen(imagen: string) {
    return UtilsPublicResources.getImageFromImages(imagen);
  }

  public get inteligencia_porcen_grasa() {
    switch (entrevistaModule.entrevista.sexo) {
      case "Hombre":
        if (entrevistaModule.entrevista.porcen_grasa < 10) {
          return "Bajo";
        }
        if (
          UtilsNumber.between(entrevistaModule.entrevista.porcen_grasa, 10, 20)
        ) {
          return "Normal";
        }
        if (
          UtilsNumber.between(entrevistaModule.entrevista.porcen_grasa, 20, 25)
        ) {
          return "Sobrepeso";
        }
        if (entrevistaModule.entrevista.porcen_grasa > 25) {
          return "Obesidad";
        }

        break;
      case "Mujer":
        if (entrevistaModule.entrevista.porcen_grasa < 20) {
          return "Bajo";
        }
        if (
          UtilsNumber.between(entrevistaModule.entrevista.porcen_grasa, 20, 30)
        ) {
          return "Normal";
        }
        if (
          UtilsNumber.between(entrevistaModule.entrevista.porcen_grasa, 30, 35)
        ) {
          return "Sobrepeso";
        }
        if (entrevistaModule.entrevista.porcen_grasa > 35) {
          return "Obesidad";
        }

        break;
    }
    return "";
  }

  public get inteligencia_imc() {
    let metros = entrevistaModule.entrevista.altura;
    let imc = this.syncedvalor / (metros * metros);
    let prefix = "Según el IMC : ";
    if (imc < 18.5) {
      return prefix + "Bajo peso";
    }
    if (UtilsNumber.between(imc, 18.5, 24.9)) {
      return prefix + "Peso normal";
    }
    if (UtilsNumber.between(imc, 25, 29.9)) {
      return prefix + "Pre-obesidad o Sobrepeso";
    }
    if (UtilsNumber.between(imc, 30, 34.9)) {
      return prefix + "Obesidad clase I";
    }
    if (UtilsNumber.between(imc, 35, 39.9)) {
      return prefix + "Obesidad clase II";
    }
    if (imc > 40) {
      return prefix + "Obesidad clase III";
    }

    return "";
  }

  public get inteligencia_medicion_muneca() {
    let metros = entrevistaModule.entrevista.altura * 100;
    let medicion_res = "Tipo de complexión : ";
    switch (entrevistaModule.entrevista.sexo) {
      case "Mujer":
        if (this.syncedvalor < 10.0) {
          medicion_res = "Grande";
        } else if (UtilsNumber.between(this.syncedvalor, 10, 11)) {
          return "Normal";
        } else if (this.syncedvalor > 11) {
          medicion_res = "Pequeña";
        }
        break;
      case "Hombre":
        if (this.syncedvalor < 9.6) {
          medicion_res = "Grande";
        } else if (UtilsNumber.between(this.syncedvalor, 9.6, 10.4)) {
          medicion_res = "Normal";
        } else if (this.syncedvalor > 10.4) {
          medicion_res = "Pequeña";
        }
        break;
    }
    /*
    if (this.syncedvalor < 17.5) {
      medicion_res = medicion_res + " " + " Ectomorfo";
    }
    if (UtilsNumber.between(this.syncedvalor, 17.5, 20)) {
      medicion_res = medicion_res + " " + " Mesomorfo";
    }
    if (this.syncedvalor > 20) {
      medicion_res = medicion_res + " " + " Endomorfo";
    }*/
    return medicion_res;
  }

  public get inteligencia_medicion_cintura() {
    //La medida ideal (saludable) de cintura, sería calculando la relación cintura-altura. Se mide así:
    let medida = this.syncedvalor / (entrevistaModule.entrevista.altura * 100);
    if (medida > 0.5) {
      return "Mayor riesgo de padecer diabetes, dislipidemias y otras condiciones cardiovasculares y metabólicas.";
    }
    return "Sin riesgo";
  }
}
